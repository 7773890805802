import React from "react";
import "./HomePage.css"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FilledInput, Grid2, IconButton, Stack, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Map from "./Map";
import { latLng, LatLng } from "leaflet";
import loadingGif from "../assets/earthAnimated.gif"



export default function HomePage() {
    // const [data, setData] = React.useState([[0]]);
    const [forcast, setForcast]: any = React.useState();
    const [userLocation, setUserLocation] = React.useState<LatLng|null>(null);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
      fetch("/api")
        .then((res) => res.json())
        // .then((data) => parseNOAAForcast(data.forcast))
        .then((data) => setForcast(forcastToMLat(parseNOAAForcast(data.forcast))))
        // .then((data) => console.log(data))
        // .then((_) => setForcast(forcastToMLat(data)))
        // .then((_) => console.log("hi there: " + forcast))
    }, []);

    async function searchForAddress() {
      const address = (document.getElementById("address-lookup") as HTMLInputElement).value
      // console.log(address)
      if (address.length > 0) {
        // console.log(address.replaceAll(" ", "+"))
        const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${address.replaceAll(" ", "+")}&format=json&polygon=1&addressdetails=1`) 
        // console.log(response)
        const json = await response.json();
        // console.log(json.length)
        if (json.length > 0){
          const addressLatLng = latLng(json[0]['boundingbox'][0], json[0]['boundingbox'][2])
          // console.log(addressLatLng)
          setUserLocation(addressLatLng)
        }
      }
    }

    return (
      <Stack 
        className="HomePage"
        sx={{ display: 'flex'}}
        padding="1rem" 
      >        
        <Box 
          sx={{
            minWidth: "20rem",
            maxWidth: "70rem",
            margin: "auto",
            border: 1,
            bgcolor: "#1b3a4b",
            alignContent: "center",
            boxShadow:1,
            borderRadius: 8
          }}
        >
          <Typography
            variant="h4"
            padding={"1.25rem"}
            color="white"
          >
            Tired of missing the Northern Lights?
          </Typography>
        </Box> 
        
        <Grid2 
          container
          spacing={2}
          sx={{
            className:"MapBox",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            padding:"1rem",
          }}
        >
          
          {/* {true ? <img src={loadingGif} alt="gif of spinning earth to indicate loading" width={'800rem'}/> : <Map */}
          {!forcast ? <img src={loadingGif} alt="gif of spinning earth to indicate loading" width={'800rem'}/> : <Map

                                          // props={{forcast: {forcast}, userLocation: {userLocation}, onMapClick: {(newLatLng: LatLng) => setUserLocation(newLatLng)} }} 
                                          forcast={forcast}
                                          userLocation={userLocation} 
                                          onMapClick={(newLatLng: LatLng) => setUserLocation(newLatLng)}
                                          />}
          <Stack
            sx={{
              backgroundColor: "#778da9",
              flexWrap: 'wrap',
              boxShadow: 10
              // color: "#ffffff"
            }}
            borderRadius={3}
            borderColor={"#000000"}
            maxHeight={"30rem"}
            maxWidth={"30rem"}
            minHeight={"20rem"}
            minWidth={"20rem"}
            direction={"row"}
          >
            <Grid2 container wrap="nowrap" height={"2rem"} width={"40rem"}>
              <TextField 
                fullWidth
                label="Address Lookup"
                variant="filled"
                id="address-lookup"

                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    searchForAddress()
                  }
                }}

                sx={{
                  // Root class for the input field
                  "& .MuiFilledInput-root": {
                    color: "#ffffff",
                    // fontFamily: "Arial",
                    fontWeight: "bold",
                    // backgroundColor: "#f4f4f4",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    "&:before": {
                      borderColor: "#2e2e2e",
                      borderWidth: "2px",
                    },
                    "&:after": {
                      borderColor: "#ffffff",
                      borderWidth: "2px",
                      color: "#ffffff"
                    },
                  },
                  // Class for the label of the filled input field
                  "& .MuiInputLabel-filled": {
                    color: "#ffffff",
                    fontWeight: "bold",
                    "&.Mui-focused": {
                      color: "#ffffff",
                      fontWeight: "bold",
                    },
                  }}}
                />
              <IconButton 
                style={{borderRadius: 4}} 
                sx={{ width: "3.5rem", height: "3.5rem", backgroundColor: "#1b3a4b", color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#aaaaaa"
                  },
                }}
                onClick={async() => {
                  searchForAddress()
                  }}>
                <SearchIcon/>
              </IconButton>
            </Grid2>
            <Typography 
              color="#ffffff"
              fontSize={28}
              width="100rem"
              height="10rem"
              sx={{
                className:"textWithinBox",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                padding:"1rem",
              }} 
              >
              {userLocation ? ("coordinates: (" + userLocation.lat + ", " + userLocation.lng + ")") : "click on the map or search an address above to see where it falls and track it!"}
            </Typography>
            <Grid2 display={"flex"} justifyContent={"center"} width={"100rem"}>
              <Button 
                variant="contained"
                // fullWidth
                disabled={(userLocation == null)}
                sx={{ backgroundColor: "#1b3a4b", width: "13rem"}}
                onClick={() => {
                  setOpen(true)
                  console.log(open)
                  }}
                >
                sign up to be notified
              </Button>
            </Grid2>
          </Stack>
        </Grid2>


        <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
            console.log(email);
            // handleClose();
          },
          sx: {
            backgroundColor: "#778da9"
          }
        }}

      >
        <DialogTitle color="#ffffff">Sign up to be notified!</DialogTitle>
        <DialogContent>
          <DialogContentText color="#ffffff">
            If you sign up, an email will be sent to you if your indicated address falls within the viewline of the Northern lights.
          </DialogContentText>
          <h3 style={{color: "#ffffff"}}>
            Location to be tracked: {(userLocation != null) ? `(${userLocation?.lat}, ${userLocation?.lng})` : "location not indicated"} 
          </h3>
          <TextField            

            // color=""
            

            sx={{
              input: {color: "#ffffff", borderColor: "#ffffff"},
              '& label.Mui-focused': {
                color: 'white',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: 'white',
              },
              '& .MuiInputLabel-root': {
                color: 'white',
              },
              '& .MuiOutlinedInput-input': {
                color: 'white',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              // border: {color: "#ffffff"}
              
              // slotProps: { color: "#ffffff"},
              // : {color: "#ffffff"}
              
              
              // ":root": {color: "#ffffff"},
              // ":before": {color: "#ffffff"},
              // "::before": {color: "#ffffff"},
              // ":after": {color: "#ffffff"}
            }}}
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{backgroundColor: "#1b3a4b", color: "#ffffff"}} onClick={() => setOpen(false)}>Cancel</Button>
          <Button sx={{backgroundColor: "#1b3a4b", color: "#ffffff"}} onClick={() => setOpen(false)} type="submit">Subscribe</Button>
        </DialogActions>
      </Dialog>
      </Stack>
    );
  }




function parseNOAAForcast(forcast: string) {
  let parsedData: any[] = forcast
                        .split("00-03UT")[1]
                        .split("Rationale:")[0]
                        .replace(/\(G\d\)+/g, "") // get rid of GeoMagnetic storms
                        .replace(/\d\d-\d\dUT+/g, "") // get rid of time zones
                        .replace(/\s+/g, " ") // remove reduntant spaces
                        .split(" ");

  parsedData.pop()
  parsedData.shift() //  gets rid of first and last space
  
  parsedData = parsedData.map((x) => parseFloat(x))
  
  let parsedForcast: any[] = []
  let todayArray: any[] = []
  let tomorrowArray: any[] = []
  let twoDaysArray: any[] = []

  while (parsedData.length > 1) {
    todayArray.push(parsedData.shift())
    tomorrowArray.push(parsedData.shift())
    twoDaysArray.push(parsedData.shift())
  }
  parsedForcast = [todayArray, tomorrowArray, twoDaysArray]

  // console.log("parseNoaa: "+ parsedForcast)

  return parsedForcast
}




function forcastToMLat(forcast: number[][] ) {
  let visibleLatForcast: number[][] = []

  forcast.forEach((day) => {
    visibleLatForcast.push(day.map((x) => (-0.02159*x**2 - (1.789 * x) + 65.625)))
  } )

  // console.log("forcastToMLat: " + visibleLatForcast)
  return visibleLatForcast
}




// reading guide:
// along the top is today, tomorrow, two days from now

// along the left is:
// '00-03UT 
// '03-06UT 
// '06-09UT 
// '09-12UT 
// '12-15UT 
// '15-18UT 
// '18-21UT 
// '21-00UT 

